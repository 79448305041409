<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Pago de Prestamo - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>
              

                <b-col md="6">
                  <b-form-group>
                    <label>Socio:</label>
                    <v-select disabled placeholder="Seleccione un socio" class="w-100" :filterable="false" label="full_name" v-model="partner" @search="SearchClients" :options="partners"></v-select>
                    <small v-if="errors.id_partner" class="form-text text-danger" >Seleccione un socio</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Pago:">
                    <b-form-input class="text-center" readonly type="text" v-model="loan_payment.code"></b-form-input>
                    <small v-if="errors.code"  class="form-text text-danger" >Ingrese un numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha de Pago:">
                    <b-form-input type="date" disabled class="text-center" v-model="loan_payment.payment_date"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Medio de Pago:">
                    <b-form-select :options="payment_method" v-model="loan_payment.payment_method"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="8">
                  <b-form-group label="Observación:">
                    <b-form-textarea type="text" v-model="loan_payment.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Cambiar Anexos:">
                     <b-form-file
                      accept="application/pdf"
                      v-model="file"
                      :state="Boolean(file)"
                      @change="onFileChange"
                      placeholder="Selecciona un archivo"
                      drop-placeholder="Arrastre el archivo aqui"
                    ></b-form-file>
                  </b-form-group>
                </b-col>

                 <div class="col-md-12">
                  <div class="table-responsive mt-3">
                        <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                          <thead>
                            <tr>
                              <th width="3%" class="text-center">#</th>
                              <th width="7%" class="text-center">Prestamo</th>
                              <th width="5%" class="text-center">Cuota</th>
                              <th width="8%" class="text-center">F. Venc.</th>
                              <th width="5%" class="text-center">Amort.</th>
                              <th width="5%" class="text-center">Int.</th>
                              <th width="5%" class="text-center">Int. Dif.</th>
                              <th width="5%" class="text-center">Seg. Desg.</th>
                              <th width="5%" class="text-center">Seg. Mul.</th>
                              <th width="8%" class="text-center">S. Capital</th>
                              <th width="8%" class="text-center">Cuota</th>
                              <th width="7%" class="text-center">Dias Mor.</th>
                              <th width="7%" class="text-center">Moro.</th>
                              <th width="9%" class="text-center">Desc.</th>
                              <th width="7%" class="text-center">Cuota Final</th>
                      

                        
                            </tr>
                          </thead>
                          <tbody v-for="(item, it) in loan_payment_detail" :key="it">
                            <tr>
                                <td class="align-middle text-center">{{ it + 1 }}</td>
                                <td class="align-middle text-center">{{ item.number }}</td>
                                <td class="align-middle text-center">{{ item.installment_number }}</td>
                                <td class="align-middle text-center">{{ item.expiration_date }}</td>
                                <td class="align-middle text-right">{{ item.amortization }}</td>
                                <td class="align-middle text-right">{{ item.interest }}</td>
                                <td class="align-middle text-right">{{ item.deferred_interest }}</td>
                                <td class="align-middle text-right">{{ item.insurance_lien }}</td>
                                <td class="align-middle text-right">{{ item.multi_insurance }}</td>
                                <td class="align-middle text-right">{{ item.principal_balance }}</td>
                                <td class="align-middle text-right">{{ item.fixed_fee }}</td>
                                <td class="align-middle text-center">{{ item.delinquent_days }}</td>
                                <td class="align-middle text-right">{{ item.delinquent_amount }}</td>
                                <td class="align-middle text-right">{{ item.discount }}</td>
                                <td class="align-middle text-right">{{ item.total_fee }}</td>
                           
                            </tr>
                          </tbody>
                        </table>
                  </div>
            </div>

               
                
                <b-col md="12">
                  <br>
                </b-col>

                <b-col md="8">
                  <b-row>
                    
                  </b-row>
                </b-col>

                <b-col md="4">
                  <b-form-group  label-cols-sm="7" label="Subtotal :" class="text-right m-0">
                    <b-form-input class="text-right" disabled type="number" step="any" v-model="loan_payment.subtotal"></b-form-input>
                  </b-form-group>
                  <b-form-group  label-cols-sm="7" label="Morosidad :" class="text-right m-0">
                    <b-form-input class="text-right" disabled type="number" step="any" v-model="loan_payment.delinquent_amount"></b-form-input>
                  </b-form-group>
                  <b-form-group  label-cols-sm="7" label="Descuento :" class="text-right m-0">
                    <b-form-input class="text-right" disabled type="number" step="any" v-model="loan_payment.discount"></b-form-input>
                  </b-form-group>
                  <b-form-group  label-cols-sm="7" label="Total :" class="text-right m-0">
                    <b-form-input class="text-right" disabled type="number" step="any" v-model="loan_payment.total"></b-form-input>
                    <small v-if="errors.total"  class="form-text text-danger" >No cuenta con aportes</small>
                  </b-form-group>
                </b-col>

                <b-col md="3"></b-col>
                <b-col md="2">
                  <b-button type="button" @click="DataPrint" class="form-control" variant="warning" ><i class="fas fa-print"></i> Imprimir Ticket</b-button>
                </b-col>
                <b-col md="2">
                  <b-button type="button" :disabled="loan_payment.file.length == 0" @click="ViewPDF" class="form-control" variant="info" ><i class="fas fa-print"></i> Ver Anexo</b-button>
                </b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "LoanPaymentView",
  props:["id_loan_payment"],
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'LoanPayment',
      role: 3,
      loan_payment: {
          id_loan_payment:'',
          id_partner:'',
          id_user:'',
          code:'',
          payment_method:'008',
          payment_date:moment(new Date()).local().format("YYYY-MM-DD"),
          observation:'',
          file:'',
          file_change:'',
          subtotal:'0.00',
          delinquent_amount:'0.00',
          discount:'0.00',
          total:'0.00',
          state:1,
      },
      loan_payment_detail:[],
      partners: [],
      partner:null,
      file:null,
      payment_method: [
        {value :"008", text :'EFECTIVO'},
        {value :"007", text :'CHEQUE'},
        {value :"001", text :'DEPÓSITO EN CUENTA'},
        {value :"003", text :'TRANSFERENCIA DE FONDOS'},
      ],
      errors: {
        id_partner: false,
        payment_method: false,
        number: false,
        contributions_detail: false,
        file: false,
        total: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewLoanPayment();
  },
  methods: {
 
    ViewLoanPayment,
    EditLoanPayment,
    Validate,
    SearchClients,

    DataPrint,
    Print,
    onFileChange,
    ViewPDF,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function onFileChange(e) {
  this.loan_payment.file_change = e.target.files[0];
}

function ViewPDF() {
  let me = this;
  let url = me.url_base + this.loan_payment.file;
  window.open(url,'_blank');
}

function DataPrint() {
  let me = this;
  let id_loan_payment = je.decrypt(this.id_loan_payment);
  let url = me.url_base + "loan-payment/data-print/"+id_loan_payment;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.Print(response.data.result)
    }
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    me.isLoading = true;
  });
}


function Print(info) {
  let url = 'http://localhost/print/consumirapi.php';
  var data = new FormData(); 
  data.append("info",JSON.stringify(info)); 

  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}


function ViewLoanPayment() {
  let me = this;
  let id_loan_payment = je.decrypt(this.id_loan_payment);
  let url = me.url_base + "loan-payment/view/"+id_loan_payment;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.loan_payment.id_loan_payment = response.data.result.loan_payment.id_loan_payment;
      me.loan_payment.id_partner = response.data.result.loan_payment.id_partner;
      me.loan_payment.id_user = response.data.result.loan_payment.id_user;
      me.loan_payment.code = response.data.result.loan_payment.code;
      me.loan_payment.payment_method = response.data.result.loan_payment.payment_method;
      me.loan_payment.payment_date = response.data.result.loan_payment.payment_date;
      me.loan_payment.observation = response.data.result.loan_payment.observation;
      me.loan_payment.file = response.data.result.loan_payment.file;
      me.loan_payment.subtotal = response.data.result.loan_payment.subtotal;
      me.loan_payment.delinquent_amount = response.data.result.loan_payment.delinquent_amount;
      me.loan_payment.discount = response.data.result.loan_payment.discount;
      me.loan_payment.total = response.data.result.loan_payment.total;
      me.loan_payment.state = response.data.result.loan_payment.state;
      me.loan_payment_detail = response.data.result.loan_payment_detail;
      me.partner = {id: response.data.result.loan_payment.id_partner,full_name: response.data.result.loan_payment.name + " - "+response.data.result.loan_payment.document_number };

    }
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    me.isLoading = true;
  });
}

function SearchClients(search, loading) {
  
   let me = this;
    let url = this.url_base + "search-partner/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.partners = response.data;
            loading(false);
      })
    }
}


function EditLoanPayment() {
  let me = this;
  let url = me.url_base + "loan-payment/edit";
 let data = new FormData();
  data.append("id_loan_payment", me.loan_payment.id_loan_payment);
  data.append("id_partner", me.partner.id);
  data.append("id_user", me.user.id_user);
  data.append("code", me.loan_payment.code);
  data.append("payment_method", me.loan_payment.payment_method);
  data.append("payment_date", me.loan_payment.payment_date);
  data.append("observation", me.loan_payment.observation);
  data.append("file", me.loan_payment.file_change);
  data.append("subtotal", me.loan_payment.subtotal);
  data.append("delinquent_amount", me.loan_payment.delinquent_amount);
  data.append("discount", me.loan_payment.discount);
  data.append("total", me.loan_payment.total);
  data.append("state", me.loan_payment.state);
  data.append("loan_payment_detail", JSON.stringify(me.loan_payment_detail));
  
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.file = null;
        me.loan_payment.file_change = '';
        me.loan_payment.file = response.data.result.file;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_partner = this.partner == null ? true : false;
  this.errors.payment_method = this.loan_payment.payment_method.length == 0 ? true : false;
  this.errors.code = this.loan_payment.code.length == 0 ? true : false;
  this.errors.loan_payment_detail = this.loan_payment_detail.length == 0 ? true : false;
  this.errors.total = parseFloat(this.loan_payment.total) == 0 ? true : false;

  if (this.errors.id_partner) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_method) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.loan_payment_detail) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar el pago del prestamo?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditLoanPayment();
    }
  });
}
</script>
